<template>
  <div>
    <heading-separator :title="title" :div="div" space="25"></heading-separator>
  </div>
</template>

<script>
import HeadingSeparator from "./HeadingSeparator.vue";

export default {
  name: "ProductsHeading",
  components: { HeadingSeparator },
  data() {
    return {
      title: "HVS - Products",
      div: "<div> <p class='white--text'> At Hedge Vision Systems, we are committed to delivering innovative solutions that address the unique challenges faced by the financial industry and asset managers. Our products are designed to enhance efficiency, data security, and collaboration, enabling organizations to thrive in today's competitive landscape.</p></div>",
    };
  },
};
</script>
